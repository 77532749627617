
function Contact () {
  return (
    <div className='page'>
      <div className='content'>
        <div id='contactText'>
          <div id='contact'>
            <p className='contactTitulo'><span style={{ color: 'white' }}>SILVESTRE NAVARRO</span>  ARQUITECTOS</p>
            <div className='columnContact'>
              <a target='_blank' rel='noreferrer' href='https://goo.gl/maps/RhTrnRLxLEZBYQgj9'>
                <p style={{ textAlign: 'left' }}>
                  CAMPOS CRESPO 57<br /> VALENCIA 46017
                </p>
              </a>
            </div>
            <div className='columnContact logos'>
              <a target='_blank' rel='noreferrer' href='https://www.instagram.com/silvestrenavarroarquitectos/'>
                <svg className='logo' viewBox='0 0 512.00096 512.00096' xmlns='http://www.w3.org/2000/svg'><path d='m373.40625 0h-234.8125c-76.421875 0-138.59375 62.171875-138.59375 138.59375v234.816406c0 76.417969 62.171875 138.589844 138.59375 138.589844h234.816406c76.417969 0 138.589844-62.171875 138.589844-138.589844v-234.816406c0-76.421875-62.171875-138.59375-138.59375-138.59375zm108.578125 373.410156c0 59.867188-48.707031 108.574219-108.578125 108.574219h-234.8125c-59.871094 0-108.578125-48.707031-108.578125-108.574219v-234.816406c0-59.871094 48.707031-108.578125 108.578125-108.578125h234.816406c59.867188 0 108.574219 48.707031 108.574219 108.578125zm0 0' /><path d='m256 116.003906c-77.195312 0-139.996094 62.800782-139.996094 139.996094s62.800782 139.996094 139.996094 139.996094 139.996094-62.800782 139.996094-139.996094-62.800782-139.996094-139.996094-139.996094zm0 249.976563c-60.640625 0-109.980469-49.335938-109.980469-109.980469 0-60.640625 49.339844-109.980469 109.980469-109.980469 60.644531 0 109.980469 49.339844 109.980469 109.980469 0 60.644531-49.335938 109.980469-109.980469 109.980469zm0 0' /><path d='m399.34375 66.285156c-22.8125 0-41.367188 18.558594-41.367188 41.367188 0 22.8125 18.554688 41.371094 41.367188 41.371094s41.371094-18.558594 41.371094-41.371094-18.558594-41.367188-41.371094-41.367188zm0 52.71875c-6.257812 0-11.351562-5.09375-11.351562-11.351562 0-6.261719 5.09375-11.351563 11.351562-11.351563 6.261719 0 11.355469 5.089844 11.355469 11.351563 0 6.257812-5.09375 11.351562-11.355469 11.351562zm0 0' /></svg>
              </a>
              <a target='_blank' rel='noreferrer' href='https://es-es.facebook.com/silvestrenavarroarquitectos/'>
                <svg className='logo' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 60.734 60.733'>
                  <path d='M57.378,0.001H3.352C1.502,0.001,0,1.5,0,3.353v54.026c0,1.853,1.502,3.354,3.352,3.354h29.086V37.214h-7.914v-9.167h7.914
                                        v-6.76c0-7.843,4.789-12.116,11.787-12.116c3.355,0,6.232,0.251,7.071,0.36v8.198l-4.854,0.002c-3.805,0-4.539,1.809-4.539,4.462
                                        v5.851h9.078l-1.187,9.166h-7.892v23.52h15.475c1.852,0,3.355-1.503,3.355-3.351V3.351C60.731,1.5,59.23,0.001,57.378,0.001z'
                  />
                </svg>
              </a>

            </div>
            <div className='columnContact'>
              <p style={{ textAlign: 'right' }}>
                <a href='tel:+34963842708'>tf +34 963 848 708</a>
                <br />
                <a target='_blank' rel='noreferrer' href='mailto:proyectos@silvestrenavarro.com'>proyectos@silvestrenavarro.com</a>
              </p>
            </div>
          </div>

        </div>
      </div>

    </div>
  )
}

export default Contact
