export const ImagesHome = [
    "https://i.imgur.com/zAIUCdXh.jpg",
    "https://i.imgur.com/N9PvzP6h.jpg",
    "https://i.imgur.com/KM1KK9Bh.jpg",
    "https://i.imgur.com/oizgI5Yh.jpg",
    "https://i.imgur.com/MetNeWAh.jpg",
    "https://i.imgur.com/Fgo5w7Ch.jpg",
    "https://i.imgur.com/EHIyGzYh.jpg",
    "https://i.imgur.com/RJH0e3Fh.png",
    "https://i.imgur.com/NeOwAXSh.jpg",
    "https://i.imgur.com/iTJHAE9h.jpg",
    "https://i.imgur.com/PHU6QJVh.jpg",
    "https://i.imgur.com/iCMW64Nh.png",
    "https://i.imgur.com/tWdCl69h.jpg",
    "https://i.imgur.com/g7cP4kqh.jpg",
    "https://i.imgur.com/0TE4z9Yh.jpg",
    "https://i.imgur.com/kE1hGsZh.jpg",
    "https://i.imgur.com/WUyk4Nvh.jpg",
    "https://i.imgur.com/3tAFYqZh.png",
    "https://i.imgur.com/RgrO5XDh.jpg",
    "https://i.imgur.com/hgLxWPoh.jpg",
    "https://i.imgur.com/xL6RODuh.jpg",
    "https://i.imgur.com/cZCEZVZh.jpg",
]