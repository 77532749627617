export const News = [
    {
        id:1,
        name:"Concurso Arquitectura",
        place:"Auditorium Alfafar",
        mention:"Primer Premio",
        imageURL:"https://i.imgur.com/LADyMXQh.jpg"
    },
    {
        id:2,
        name:"Concurso Arquitectura",
        place:"Plaza Picanya",
        mention:"Mención",
        imageURL:"https://i.imgur.com/NQBcHvKh.jpg"
    },
    {
        id:3,
        name:"Publicación",
        place:"Casa en la ladera de un castillo",
        mention:"Revista C3",
        imageURL:"https://i.imgur.com/N1dfsG6h.jpg"
    },
    {
        id:4,
        name:"Premios COACV",
        place:"Casa del Atrio",
        mention:"Mención",
        imageURL:"https://i.imgur.com/TFsiy2Hh.jpg"
    },
    {
        id:5,
        name:"Exposición de Fotografía",
        place:"Diego Opazao",
        mention:"El Hombre Subterráneo",
        imageURL:"https://i.imgur.com/ZAcGNp5h.jpg"
    },
    {
        id:6,
        name:"Concurso Arquitectura",
        place:"Bulevar Gandía",
        mention:"Accesit",
        imageURL:"https://i.imgur.com/qDc3v7sh.jpg"
    },
    {
        id:7,
        name:"Publicación",
        place:"Casa Esculpida",
        mention:"Revista 138AC",
        imageURL:"https://i.imgur.com/jwMkOtDh.png"
    },
    {
        id:8,
        name:"Publicación",
        place:"Auditorium Alfafar",
        mention:"Revista TC",
        imageURL:"https://i.imgur.com/UuDOsQoh.jpg"
    },
    {
        id:9,
        name:"Exposición Arquitectura",
        place:"SILVESTRE NAVARRO",
        mention:"ETSAV",
        imageURL:"https://i.imgur.com/LklprFdh.jpg"
    },
    {
        id:10,
        name:"Ponencia",
        place:"Casa del Carpintero",
        mention:"Congreso Nacional de Arquitectos",
        imageURL:"https://i.imgur.com/umWsDqwh.jpg"
    },
    {
        id:11,
        name:"Concurso Arquitectura",
        place:"Ampliación Museo Gösta",
        imageURL:"https://i.imgur.com/z7cFNwEh.jpg"
    },
    {
        id:12,
        name:"Publicación",
        place:"Casa en la ladera de un castillo",
        mention:"Revista Thisispaper",
        imageURL:"https://i.imgur.com/3hdAKDTh.png"
    },
    
    {
        id:13,
        name:"Publicación",
        place:"Publicación",
        mention:"Revista TC",
        imageURL:"https://i.imgur.com/qmLQP49h.jpg"
    },
    {
        id:14,
        name:"Exposición",
        place:"SILVESTRE NAVARRO",
        mention:"Valencia Disseny Week",
        imageURL:"https://i.imgur.com/1jHltlJh.png"

        
    },
    {
        id:15,
        name:"Publicación",
        place:"Casa del Acantilado",
        mention:"Editorial PENCIL",
        imageURL:"https://i.imgur.com/AdntClmh.jpg"
    },
    {
        id:16,
        name:"Concurso de Arquitectura",
        place:"Museo Arqueológico Calviá",
        imageURL:"https://i.imgur.com/QtmRCcLh.jpg"
    },
    {
        id:17,
        name:"Exposición Pintura",
        place:"Theo",
        mention:"Fractal",
        imageURL:"https://i.imgur.com/HJb1sgYh.jpg"
    },
    {
        id:18,
        name:"Publicación",
        place:"Arquitectura Sanitaria",
        mention:"GVA",
        imageURL:"https://i.imgur.com/MhixPOyh.jpg"
    },
    {
        id:20,
        name:"Exposición",
        place:"Auditorium Alfafar",
        mention:"Sala Edgar Neville",
        imageURL:"https://i.imgur.com/jtwXAqth.jpg"
    },

    // {
    //     id:"no esta aun",
    //     name:"Concurso Arquitectura",
    //     place:"Plaza Picanya",
    //     imageURL:"https://i.imgur.com/i7nuWRBh.jpg"
    // },
    // {
    //     id:22,
    //     name:"Concurso Arquitectura",
    //     place:"Auditorium Alfafar",
    //     mention:"Primer Premio",
    //     imageURL:"https://i.imgur.com/6dsBr0gh.jpg"
    // }
    
    
    
    
    
]



































