
import { useState, useEffect } from 'react'

export default function ProjectDetails ({ project, projectImage, setProjectImage }) {
  const [effect, setEffect] = useState('')
  const [hover, setHover] = useState(false)

  useEffect(

    () => {
      if (hover === false) {
        const timer1 = setTimeout(() => nextImageProject(), 3000)
        return () => {
          clearTimeout(timer1)
        }
      }
    },
    [projectImage, hover]
  )

  const delay = ms => new Promise(res => setTimeout(res, ms))

  const nextImageProject = async () => {
    setEffect('change')
    await delay(500)
    if (projectImage === project.imageURL.length - 1) {
      setProjectImage(0)
    } else {
      setProjectImage(projectImage + 1)
    }

    setEffect('')
  }
  const prevImageProject = async () => {
    setEffect('change')
    await delay(500)
    if (projectImage === 0) {
      setProjectImage(project.imageURL.length - 1)
    } else {
      setProjectImage(projectImage - 1)
    }
    setEffect('')
  }

  return (
    <div id='projectImages'>
      <div id='imageButton'> <button className='button' onClick={prevImageProject}>❮</button></div>
      <div id='imageBox'>
        <img onMouseEnter={() => setHover(true)} onMouseLeave={() => setHover(false)} className={`projectImage ${effect === 'change' ? 'change' : 'normal'}`} src={project.imageURL[projectImage]} />
      </div>
      <div id='imageButton' style={{ width: '2vw' }}><button className='button' onClick={nextImageProject}>❯</button></div>
    </div>

  )
}
