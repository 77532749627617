import React from 'react'
import ReactDOM from 'react-dom'

const Modal = ({ isShowing, hide, project, projectImage, setProjectImage }) => isShowing
  ? ReactDOM.createPortal(
    <>
      <div className='modal-overlay' />
      <div className='modal-wrapper' aria-modal aria-hidden tabIndex={-1} role='dialog'>
        <div className='modal'>
          <div className='modal-header'>
            <button type='button' className='modal-close-button' data-dismiss='modal' aria-label='Close' onClick={hide}>
              <span aria-hidden='true'>&times;</span>
            </button>
          </div>
          <div className='modal-body'>
            <p style={{ color: 'white' }}>
              {project.name}
            </p>
            <p>
              {project.fullName}
            </p>
            <p>
              {project.location}
              <br />
              {project.year}
              <br />
              {project.description}
              <br />
              {project.photographer ? `Fotografía: ${project.photographer}` : ''}
            </p>

            {project.imageURL.map((item, index) => (
              <div key={index}>
                <img src={item} alt='imagen' />
              </div>
            ))}
            {/* <ProjectDetails project={project} projectImage={projectImage} setProjectImage={setProjectImage} /> */}

          </div>
        </div>
      </div>
    </>, document.body
    )
  : null

export default Modal
