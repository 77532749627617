import {Projects1} from './projects_1'
import {Projects2} from './projects_2'
import {Projects3} from './projects_3'


Projects1.sort(() => Math.random() - 0.5);
Projects2.sort(() => Math.random() - 0.5);
Projects3.sort(() => Math.random() - 0.5);

let Projects = []

Projects = Projects.concat(Projects1).concat(Projects2).concat(Projects3)

export default  Projects