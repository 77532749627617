import './styles/global.css'
import './styles/stylesComputer.css'
import './styles/stylesTablet.css'
import './styles/stylesPhone.css'

import {
  BrowserRouter as Router,
  Switch,
  Route,
  NavLink
} from 'react-router-dom'

import ListOfProyects from './components/listOfProyects'
import Actualidad from './components/actualidad'
import Contact from './components/contact'
import Home from './components/home'
import { useState, useEffect } from 'react'

function App () {
  const [width, setWidth] = useState(window.innerWidth)

  useEffect(() => {
    const handleResize = () => setWidth(window.innerWidth)
    window.addEventListener('resize', handleResize)
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  })

  return (
    <Router>
      <div id='header'>
        <h1>
          <NavLink to='/'><p className='headerLink'>SILVESTRE NAVARRO </p></NavLink>
        </h1>
      </div>

      <Switch>
        <Route path='/proyects'>
          <ListOfProyects width={width} />
        </Route>
        <Route path='/actualidad'>
          <Actualidad width={width} />
        </Route>
        <Route path='/contact'>
          <Contact />
        </Route>
        <Route path='/'>
          <Home />
        </Route>
      </Switch>

      <div id='footer'>
        <div id='nav'>
          <NavLink className='linkMenu' activeClassName='selected' exact to='./proyects'>PROYECTOS</NavLink>
          <NavLink className='linkMenu' activeClassName='selected' exact to='./actualidad'>ACTUALIDAD | INFORMACIÓN</NavLink>
          <NavLink className='linkMenu' activeClassName='selected' exact to='./contact'>CONTACTO</NavLink>
        </div>

      </div>
    </Router>

  )
}

export default App
