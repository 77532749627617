import { News } from './resources/news'
import Carousel from 'react-elastic-carousel'

function Actualidad ({ width }) {
  const breakPoints = [
    // { width: 1, itemsToShow: 1 },
    // { width: 550, itemsToShow: 4, itemsToScroll: 2 },
    { width: 1, itemsToShow: 4, itemsToScroll: 4 },
    { width: 1023, itemsToShow: 5, itemsToScroll: 5 }
  ]

  const news = News

  return (
    <div className='page'>
      <div className='content'>

        {
            width > 600
              ? <div id='actualidadImages'>
                <div id='actualidadCarousel'>
                  <Carousel itemPadding={[0, 10, 0, 10]} breakPoints={breakPoints} pagination={false}>
                    {news.map((item, index) => (
                      <div key={index} className='proyecto'>
                        <img src={item.imageURL} alt={item.name} />
                        <p>
                          {item.name}
                          <br />
                          <strong>{item.place}</strong>
                        </p>
                        {item.mention ? <p>{item.mention}</p> : <p style={{ color: 'white' }}>@pablosantos21</p>}
                      </div>
                    ))}
                  </Carousel>
                </div>
              </div>
              : <div className='actualidadPhone'>
                {news.map((item, index) => (
                  <div key={index} className='proyecto'>
                    <img src={item.imageURL} alt={item.name} />
                    <p style={{ fontSize: '1.2em' }}>
                      {item.name}
                      <br />
                      <strong>{item.place}</strong>
                    </p>
                    {item.mention ? <p>{item.mention}</p> : <p style={{ color: 'white' }}>@pablosantos21</p>}

                  </div>
                ))}
                </div>
          }

      </div>
      <div />
    </div>
  )
}
export default Actualidad
