export const Projects2 = [
  {
    name: 'ALR',
    imageURL: [
      'https://i.imgur.com/fHraOCsh.jpg',
      'https://i.imgur.com/zWUvq91h.jpg',
      'https://i.imgur.com/kGTjvvah.jpg',
      'https://i.imgur.com/4tXFMwNh.jpg'
    ],
    fullName: 'Viviendas con corredor',
    location: 'Alfafar',
    // year: 2007,
    // description:'',
    photographer: 'Estudio Silvestre Navarro'

  },
  {
    name: 'ALT',
    imageURL: [
      'https://i.imgur.com/aw2iNG0h.jpg',
      'https://i.imgur.com/zEelxath.png',
      'https://i.imgur.com/qOtsbnIh.jpg',
      'https://i.imgur.com/yIZbB03h.png',
      'https://i.imgur.com/vV3htHFh.png'
    ],
    fullName: 'Casa en el acantilado',
    location: 'Calpe',
    // year: 2006,
    // description:'',
    photographer: 'Fernando Alda'

  },
  {
    name: 'BLM',
    imageURL: [
      'https://i.imgur.com/eP0907bh.jpg',
      'https://i.imgur.com/d148a8fh.jpg',
      'https://i.imgur.com/9wonHVvh.jpg',
      'https://i.imgur.com/bHZM3eXh.jpg',
      'https://i.imgur.com/wMAACQlh.jpg',
      'https://i.imgur.com/7B0Skf2h.jpg',
      'https://i.imgur.com/UbvFCORh.jpg'
    ],
    fullName: 'Showroom Engonari',
    location: 'Valencia',
    // year: 2013,
    // description:'',
    photographer: 'Mayte Piera, Estudio Silvestre Navarro'

  },
  {
    name: 'CLL',
    imageURL: [
      'https://i.imgur.com/gOetHXQh.jpg',
      'https://i.imgur.com/FfmnSQHh.jpg',
      'https://i.imgur.com/MD5AkRwh.jpg',
      'https://i.imgur.com/SHH5bRDh.jpg',
      'https://i.imgur.com/3ULCxFih.jpg',
      'https://i.imgur.com/tZJBUmSh.jpg',
      'https://i.imgur.com/Z4f4Urjh.jpg',
      'https://i.imgur.com/mThPTkUh.jpg',
      'https://i.imgur.com/dzXsOMIh.jpg',
      'https://i.imgur.com/gxlXYlGh.jpg',
      'https://i.imgur.com/AS5gevih.png',
      'https://i.imgur.com/KPK92jZh.png',
      'https://i.imgur.com/8TY5gzhh.png'
    ],
    fullName: 'Oficinas en la antigua imprenta',
    location: 'Patraix',
    // year: 2008,
    // description:'',
    photographer: 'Diego Opazo'

  },
  {
    name: 'SBR',
    imageURL: [
      'https://i.imgur.com/xGzpuQQh.png',
      'https://i.imgur.com/94POTT4h.png',
      'https://i.imgur.com/j3pQ870h.png',
      'https://i.imgur.com/VPnBPJVh.jpg',
      'https://i.imgur.com/n2Kv9VCh.png',
      'https://i.imgur.com/mRzmp4dh.png',
      'https://i.imgur.com/ideZdc9h.png',
      'https://i.imgur.com/4FUcbLKh.png',
      'https://i.imgur.com/mvwwVYYh.jpg',
      'https://i.imgur.com/GUJwKtDh.jpg',
      'https://i.imgur.com/RdnHYS0h.jpg',
      'https://i.imgur.com/SZ3Xkojh.jpg',
      'https://i.imgur.com/XRz68C3h.jpg',
      'https://i.imgur.com/RdYFJa4h.jpg'
    ],
    fullName: 'Casa del Atrio',
    location: 'Godella',
    // year: 2006,
    // description:'',
    photographer: 'Fernando Alda'

  },
  {
    name: 'STF',
    imageURL: [
      'https://i.imgur.com/lpcO0J7h.jpg',
      'https://i.imgur.com/tHN8qVvh.jpg',
      'https://i.imgur.com/z64CaRYh.jpg',
      'https://i.imgur.com/6dcDrDoh.jpg',
      'https://i.imgur.com/sOuiM2oh.jpg',
      'https://i.imgur.com/u6R62nsh.jpg',
      'https://i.imgur.com/tEESfPkh.jpg',
      'https://i.imgur.com/Zk0Ba9Xh.jpg',
      'https://i.imgur.com/ZKxMrKsh.jpg'
    ],
    fullName: 'Oficinas STAFF',
    location: 'Valencia',
    // year: 2014,
    // description:'',
    photographer: 'Mayte Piera'
  },
  {
    name: 'VVS',
    imageURL: [
      'https://i.imgur.com/8Ue3vP0h.jpg',
      'https://i.imgur.com/OyjY5hLh.jpg',
      'https://i.imgur.com/PvpUVERh.jpg',
      'https://i.imgur.com/Xe1pMD5h.jpg',
      'https://i.imgur.com/ed5rMWYh.jpg',
      'https://i.imgur.com/poMJ4qyh.jpg',
      'https://i.imgur.com/k5nN7MXh.jpg',
      'https://i.imgur.com/u54Kax7h.jpg'
    ],
    fullName: 'Apartamento en Ruzafa',
    location: 'Ruzafa',
    // year: 2015,
    // description:'',
    photographer: 'Diego Opazo'

  }
]
