
import { useState } from 'react'
import Projects from './resources/projects'
import Carousel from 'react-elastic-carousel'
import Modal from './modal'
import useModal from './hooks/useModal'
import ProjectDetails from './projectDetails'

function ListOfProyects ({ width }) {
  const breakPoints = [
    { width: 1, itemsToShow: 1 },
    { width: 500, itemsToShow: 6, itemsToScroll: 6 },
    { width: 1023, itemsToShow: 12, itemsToScroll: 12 }
  ]

  const projects = Projects
  const [project, setProject] = useState(Projects[0])
  const [projectImage, setProjectImage] = useState(0)
  const { isShowing, toggle } = useModal()

  const projectSelected = (x) => {
    setProject(x)
    setProjectImage(0)
  }

  return (
    <div>
      {
          width > 599

            ? <div className='page'>
              <div className='content'>
                <ProjectDetails project={project} projectImage={projectImage} setProjectImage={setProjectImage} />
                <div id='projectText'>
                  <div>
                    <p style={{ color: 'white' }}>
                      {project.name}
                    </p>
                    <p className='projectT'>
                      {project.fullName}
                    </p>
                    <p className='projectT'>
                      {project.location}
                      <br />
                      {project.year}
                    </p>
                    <p>
                      {project.description}
                    </p>
                  </div>
                  <div className='projectTextPhotographer'>
                    <p>{project.photographer ? `Fotografía: ${project.photographer}` : ''}</p>
                  </div>
                </div>
              </div>
              <div id='carousel'>
                <div id='carouselMenu'>
                  <Carousel breakPoints={breakPoints} pagination={false}>
                    {projects.map((item, index) => (
                      <div key={index} onClick={() => projectSelected(item)} className='proyecto'>
                        <img className='imageMenu' src={item.imageURL[0]} alt={item.name} />
                        {
                      project.name === item.name ? <p style={{ color: 'white' }}>{item.name}</p> : <p>{item.name}</p>
                    }

                      </div>
                    ))}
                  </Carousel>
                </div>
              </div>
            </div>
            : <div className='projectsPhone'>
              <Modal
                isShowing={isShowing}
                hide={toggle}
                project={project}
                projectImage={projectImage} setProjectImage={setProjectImage}
              />
              {projects.map((item, index) => (
                <div key={index} className='projectPhone' onClick={toggle}>
                  <img src={item.imageURL[0]} className='projectImage' alt={item.name} onClick={() => projectSelected(item)} />
                  <p>{item.name}</p>
                </div>
              ))}
            </div>
        }
    </div>

  )
}
export default ListOfProyects
