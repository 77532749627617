export const Projects1 = [
  {
    name: 'CLV',
    imageURL: [
      'https://i.imgur.com/E1918eoh.jpg',
      'https://i.imgur.com/bDXfqyOh.jpg',
      'https://i.imgur.com/0QZ7ytvh.jpg',
      'https://i.imgur.com/el8RZ1eh.jpg',
      'https://i.imgur.com/XzvrNaeh.jpg',
      'https://i.imgur.com/mcgBiTvh.jpg',
      'https://i.imgur.com/9uXjarVh.jpg',
      'https://i.imgur.com/52jx55bh.jpg',
      'https://i.imgur.com/vbMI6hvh.jpg',
      'https://i.imgur.com/UW6fhigh.jpg',
      'https://i.imgur.com/7cRqoGzh.jpg'
    ],
    fullName: 'Concurso Museo Arqueológico',
    location: 'Calviá'
    // year: 2010,
    // description:'',

  },
  {
    name: 'ELN',
    imageURL: [
      'https://i.imgur.com/wt1klNeh.jpg',
      'https://i.imgur.com/iJGmOjKh.jpg',
      'https://i.imgur.com/SLpVQmKh.jpg',
      'https://i.imgur.com/3CbhSH3h.jpg',
      'https://i.imgur.com/9aelGJkh.jpg',
      'https://i.imgur.com/p6Ey9hqh.jpg',
      'https://i.imgur.com/z5VRDd2h.jpg',
      'https://i.imgur.com/Mq1rpp9h.jpg',
      'https://i.imgur.com/Q5W0F0Oh.jpg',
      'https://i.imgur.com/ItnOshvh.jpg',
      'https://i.imgur.com/q5lcZeuh.jpg',
      'https://i.imgur.com/kxAEKURh.jpg',
      'https://i.imgur.com/Zu5W6mXh.jpg',
      'https://i.imgur.com/ky9T2Bhh.jpg',
      'https://i.imgur.com/W6o0mH8h.jpg',
      'https://i.imgur.com/TYq5Vpoh.jpg',
      'https://i.imgur.com/zh02E6Qh.jpg',
      'https://i.imgur.com/Z9tlrK4h.jpg',
      'https://i.imgur.com/U9AYESwh.jpg'

    ],
    fullName: 'Casa Esculpida',
    location: 'L\'Eliana',
    // year: 2005,
    // description:'',
    photographer: 'Diego Opazo, Mayte Piera, Juan Rodríguez'
  },
  {
    name: 'FLN',
    imageURL: [
      'https://i.imgur.com/YfLNC3Lh.jpg',
      'https://i.imgur.com/ohqRFGSh.jpg',
      'https://i.imgur.com/d4jxzqrh.jpg',
      'https://i.imgur.com/aaQ8WT6h.jpg',
      'https://i.imgur.com/NzxSNoJh.jpg',
      'https://i.imgur.com/yfqHMpRh.jpg',
      'https://i.imgur.com/r1p7bPMh.jpg',
      'https://i.imgur.com/qxW32ZGh.jpg',
      'https://i.imgur.com/kVTP28Uh.jpg',
      'https://i.imgur.com/Ow5PxWjh.jpg',
      'https://i.imgur.com/Y9cetP1h.jpg'
    ],
    fullName: 'Concurso Gösta Serlachius Fine Arts Foundation',
    location: 'Gösta'
    // year: 2011,
    // description:'',

  },
  {
    name: 'HER',
    imageURL: [
      'https://i.imgur.com/YXGHychh.png',
      'https://i.imgur.com/ikjdQTFh.jpg',
      'https://i.imgur.com/3cHhYHHh.jpg',
      'https://i.imgur.com/13hlzt0h.jpg',
      'https://i.imgur.com/fccLL4Ph.jpg',
      'https://i.imgur.com/BNYCmFGh.jpg',
      'https://i.imgur.com/YXIRh5Uh.jpg',
      'https://i.imgur.com/WEkZmVUh.jpg',
      'https://i.imgur.com/Rxse3oRh.jpg',
      'https://i.imgur.com/MiiKpevh.jpg',
      'https://i.imgur.com/WNiBjgoh.jpg',
      'https://i.imgur.com/ezHKSiWh.jpg'
    ],
    fullName: 'Casa en la ladera de un Castillo',
    location: 'Ayora',
    // year: 2004,
    // description:'',
    photographer:
      'Estudio Silvestre Navarro, Fernando Alda'

  },
  {
    name: 'NSA',
    imageURL: [
      'https://i.imgur.com/LYyWqelh.jpg',
      'https://i.imgur.com/mrDmt3Lh.jpg',
      'https://i.imgur.com/JtJ5sMUh.jpg',
      'https://i.imgur.com/D80srv4h.jpg',
      'https://i.imgur.com/LDPcHAqh.jpg',
      'https://i.imgur.com/wNskBO7h.jpg',
      'https://i.imgur.com/xYmV0CTh.jpg',
      'https://i.imgur.com/h3I0xjWh.jpg',
      'https://i.imgur.com/7fStzFEh.jpg',
      'https://i.imgur.com/7jGgsmQh.jpg',
      'https://i.imgur.com/X9mSE7th.jpg',
      'https://i.imgur.com/atHR2K4h.jpg'
    ],
    fullName: 'Concurso para hospital',
    location: 'Valencia'
    // year: 2010,
    // description:'',

  },
  {
    name: 'SLR',
    imageURL: [
      'https://i.imgur.com/al7rs3Yh.jpg',
      'https://i.imgur.com/YSM1L7Mh.jpg',
      'https://i.imgur.com/sxI8YgAh.jpg',
      'https://i.imgur.com/qtGAooph.jpg',
      'https://i.imgur.com/Kqao6w0h.jpg',
      'https://i.imgur.com/jiv2bJih.jpg',
      'https://i.imgur.com/pzU7WUhh.jpg',
      'https://i.imgur.com/unBoalGh.jpg',
      'https://i.imgur.com/NgdKjf6h.jpg',
      'https://i.imgur.com/Xt4b8cWh.jpg',
      'https://i.imgur.com/3ZAATTUh.jpg',
      'https://i.imgur.com/EfnUvt5h.jpg',
      'https://i.imgur.com/9wvEzJUh.jpg',
      'https://i.imgur.com/EFkvrJ7h.jpg',
      'https://i.imgur.com/oin7EXDh.jpg',
      'https://i.imgur.com/ZcLKPqKh.jpg'
    ],
    fullName: 'Casa del granero',
    location: 'Pinedo',
    // year: 2009,
    // description:'',
    photographer: 'Diego Opazo'

  },
  {
    name: 'SRR',
    imageURL: [
      'https://i.imgur.com/7O8BrbLh.jpg',
      'https://i.imgur.com/qWAZChmh.jpg',
      'https://i.imgur.com/DGieLklh.jpg',
      'https://i.imgur.com/O2qQNhAh.jpg',
      'https://i.imgur.com/mzIn4b7h.jpg',
      'https://i.imgur.com/oJuVmVMh.jpg',
      'https://i.imgur.com/qIid03Ih.jpg',
      'https://i.imgur.com/ao2XjCbh.jpg',
      'https://i.imgur.com/zOoyazoh.jpg',
      'https://i.imgur.com/H2o49eih.jpg',
      'https://i.imgur.com/KZFCo6wh.jpg',
      'https://i.imgur.com/2ScdySOh.jpg',
      'https://i.imgur.com/fRtkoalh.jpg',
      'https://i.imgur.com/WwYXKAXh.jpg',
      'https://i.imgur.com/X8sYsRZh.jpg'
    ],
    fullName: 'Casa del carpintero',
    location: 'L\'Eliana',
    // year: 2006,
    // description:'',
    photographer: 'Diego Opazo'

  }

]
