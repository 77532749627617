export const Projects3 = [
  {
    name: 'BET',
    imageURL: [
      'https://i.imgur.com/ISkGHYlh.jpg',
      'https://i.imgur.com/Z3DVNl8h.jpg',
      'https://i.imgur.com/A3fwTjXh.jpg',
      'https://i.imgur.com/Skgx1xwh.jpg',
      'https://i.imgur.com/0pfueTWh.jpg',
      'https://i.imgur.com/SApVKC3h.jpg',
      'https://i.imgur.com/yf1b2WKh.jpg',
      'https://i.imgur.com/XLiRXkNh.png'
    ],
    fullName: 'Complejo comercial y viviendas',
    location: 'Bétera'
    // year: 2003,
    // description:'',
  },
  {
    name: 'CRA',
    imageURL: [
      'https://i.imgur.com/jBOFGcKh.jpg',
      'https://i.imgur.com/s4nvbivh.jpg',
      'https://i.imgur.com/KVLEAeth.jpg',
      'https://i.imgur.com/umDoh0rh.jpg'
    ],
    fullName: 'Conjunto de viviendas escalonadas',
    location: 'Cullera'
    // year: 2007,
    // description:'',
  },
  {
    name: 'DGO',
    imageURL: [
      'https://i.imgur.com/9K573Tjh.jpg',
      'https://i.imgur.com/leXFZnrh.jpg',
      'https://i.imgur.com/cgqb3zYh.jpg',
      'https://i.imgur.com/O1sYnK2h.jpg',
      'https://i.imgur.com/lEH42FIh.jpg',
      'https://i.imgur.com/zT4tIIKh.jpg',
      'https://i.imgur.com/K13fYZLh.jpg'
    ],
    fullName: 'Exposición de fotografía: Diego Opazo',
    location: 'Valencia'
    // year: 2011,
    // description:'',
  },
  {
    name: 'ECO',
    imageURL: [
      'https://i.imgur.com/1hYCQJPh.jpg',
      'https://i.imgur.com/YJemp6Xh.jpg',
      'https://i.imgur.com/Au2heZeh.jpg',
      'https://i.imgur.com/cCcvda3h.jpg',
      'https://i.imgur.com/vgXa6H2h.jpg',
      'https://i.imgur.com/yiy37v9h.jpg',
      'https://i.imgur.com/AOrfjK9h.jpg',
      'https://i.imgur.com/iTzdhlth.jpg'
    ],
    fullName: 'Concurso Auditorio ',
    location: 'Alfafar'
    // year: 2005,
    // description:'',
  },
  {
    name: 'FTN',
    imageURL: [
      'https://i.imgur.com/1GssTMFh.jpg',
      'https://i.imgur.com/NQBcHvKh.jpg',
      'https://i.imgur.com/MybMmYUh.jpg',
      'https://i.imgur.com/clriElPh.jpg',
      'https://i.imgur.com/8Brt8SVh.jpg',
      'https://i.imgur.com/Kvvytpph.jpg',
      'https://i.imgur.com/rMsLrB4h.png',
      'https://i.imgur.com/8BRKoCIh.jpg'
    ],
    fullName: 'Concurso remodelación centro',
    location: 'Picanya'
    // year: 2010,
    // description:'',
  },
  {
    name: 'GND',
    imageURL: [
      'https://i.imgur.com/juGx6PSh.jpg',
      'https://i.imgur.com/m3im5Onh.jpg',
      'https://i.imgur.com/CVkLZJTh.jpg',
      'https://i.imgur.com/eL6r45Qh.jpg',
      'https://i.imgur.com/GNDODKOh.jpg',
      'https://i.imgur.com/A0eFPydh.jpg',
      'https://i.imgur.com/iawb4Rvh.jpg',
      'https://i.imgur.com/8ITLhLph.jpg',
      'https://i.imgur.com/GvAc6ABh.jpg',
      'https://i.imgur.com/ZgD2Lh1h.jpg',
      'https://i.imgur.com/pVfZNath.jpg'
    ],
    fullName: 'Concurso bulevar ',
    location: 'Gandía'
    // year: 2010,
    // description:''
  },
  {
    name: 'LBR',
    imageURL: [
      'https://i.imgur.com/RB29SZeh.jpg',
      'https://i.imgur.com/cBNOgxch.jpg',
      'https://i.imgur.com/YvY6ixyh.jpg'
    ],
    fullName: 'Libro Arquitectura Sanitaria',
    location: '',
    // year: 2010,
    // description:'',
    photographer: 'Diego Opazo, Estudio Silvestre Navarro'

  }
]
