import { ImagesHome } from './resources/imagesHome'
import { useState } from 'react'
import ProjectDetails from './projectDetails'

function Home () {
  const images = { imageURL: ImagesHome }

  const [image, setImage] = useState(0)

  return (
    <div className='page'>

      <div className='content contentHome'>
        <div className='carouselHome'>
          <ProjectDetails project={images} projectImage={image} setProjectImage={setImage} />
        </div>
        <div id='homeText'>
          <div id='text'>
            <p>
              Silvestre Navarro Arquitectos
            </p>
            <br />
            <p>
              Estudio de arquitectura en Valencia
            </p>
            <br />
            <p className='paraHome'>
              Silvestre Navarro somos un equipo fundado en 1993 dedicado a trabajar y
              aprender a través de la arquitectura, interesado en la creación de
              lugares, espacios y objetos, y comprometido en la definición precisa
              de estos con independencia de su naturaleza y escala.
            </p>
            <br />
            <p className='paraHome'>
              Nuestro estudio ocupa en la actualidad lo que antaño fuera una imprenta
              en una antigua manzana industrial del casco urbano de Valencia. Un lugar
              donde practicar el oficio y realizar nuestras inquietudes, un lugar de
              exposiciones, de encuentros y charlas donde compartimos nuestros trabajos
              con otros gremios.
            </p>
          </div>

        </div>
      </div>
    </div>
  )
}

export default Home
